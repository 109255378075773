<template>
    <section class="advanced-form-elements new-updated-design">
        <vue-snotify></vue-snotify>
        <system_app_settings></system_app_settings>
            <br>
        <div class="row">
            <div class="col-md-12">
                <form class="forms-sample" @submit.stop.prevent="onSubmit">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('map-settings') }}</h4>
                        </div>
                        <div class="card-body new-card-body">
                            <div class="row mt-4">
                                <div class="col-md-4 dis-flex">
                                    <label class="" for="inline-form-custom-select-pref">{{ $t('latitude') }}</label>
                                </div>
                                <div class="col-md-8">
                                    <b-form-input
                                            id="inline-form-custom-select-pref"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            v-model="$v.form.latitude.$model"
                                            :state="$v.form.latitude.$dirty ? !$v.form.latitude.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-if="!$v.form.latitude.required"
                                                             id="input-1-live-feedback">{{ $t('field-required') }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.latitude.between"
                                                             id="input-2-live-feedback">
                                     {{ $t("Value must be between -90 and 90") }}
                                    </b-form-invalid-feedback>
                                </div>


                                <div class="col-md-4 dis-flex">
                                    <label class="text-left" for="inline-form-custom-select-pref">{{ $t('longitude') }}</label>
                                </div>
                                <div class="col-md-8">
                                    <b-form-input
                                            id="inline-form-custom-select-pref"
                                            class="mb-2 mr-sm-2 mt-2 mb-sm-0"
                                            v-model="$v.form.longitude.$model"
                                            :state="$v.form.longitude.$dirty ? !$v.form.longitude.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-if="!$v.form.longitude.required"
                                                             id="input-1-live-feedback">{{ $t('field-required') }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.longitude.between"
                                                             id="input-2-live-feedback">
                                    {{ $t("Value must be between -180 and 180") }}
                                    </b-form-invalid-feedback>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-4 dis-flex">
                                    <label class="text-left" for="inline-form-custom-select-pref">{{ $t('default-map') }}</label>
                                </div>
                                <div class="col-md-8">
                                    <input id="satellite-v9" type="radio" v-model="form.default_map"
                                    @click="saveMapStyleee('satellite-v9')"
                                           value="mapbox://styles/mapbox/satellite-v9"
                                           :checked="form.default_map === 'mapbox://styles/mapbox/satellite-v9'">
                                    <label class="map-label" for="satellite-v9">{{ $t('satellite') }}</label>
                                    <input id="light-v10" type="radio" class="ml-2" v-model="form.default_map"
                                    @click="saveMapStyleee('light-v10')"
                                           :checked="form.default_map === 'mapbox://styles/mapbox/light-v10'"
                                           value="mapbox://styles/mapbox/light-v10">
                                    <label class="map-label" for="light-v10">{{ $t('light') }}</label>
                                    <input id="dark-v10" type="radio" class="ml-2" v-model="form.default_map"
                                    @click="saveMapStyleee('dark-v10')"
                                           :checked="form.default_map === 'mapbox://styles/mapbox/dark-v10'"
                                           value="mapbox://styles/mapbox/dark-v10">
                                    <label class="map-label" for="dark-v10">{{ $t('dark') }}</label>
                                    <!--            <input id="streets-v11" type="radio" v-model="selectedMapStyle" @click="saveMapStyle('streets-v11')" value="streets">-->
                                    <!--            <label for="streets-v11">streets</label>-->
                                    <input id="outdoors-v11" type="radio" class="ml-2" v-model="form.default_map"
                                           :checked="form.default_map === 'mapbox://styles/mapbox/outdoors-v11'"
                                           @click="saveMapStyleee('outdoors-v11')"
                                           value="mapbox://styles/mapbox/outdoors-v11">
                                    <label class="map-label" for="outdoors-v11">{{ $t('outdoors') }}</label>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4 dis-flex">
                                    <label class="text-left" for="inline-form-custom-select-pref">{{ $t('map-zoom') }}</label>
                                </div>
                                <div class="col-md-8">
                                    <vue-slide-bar class="pt-3" v-model="form.default_map_zoom" :min="1" :max="22" :processStyle="sliderCustomzie.processStyle" :lineHeight="sliderCustomzie.lineHeight" :tooltipStyles="sliderCustomzie.tooltipStyles"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <b-button type="submit" variant="success" class="mr-4 mb-3 float-right"
                                          :disabled="isDisabled">{{ $t('submit-btn') }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script lang="js">
    import VueTrix from "vue-trix";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import Vue from "vue";
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import CkEditorBuild from 'ckeditor5-custom-build/build/ckeditor';
    import UploadAdapter from '@/UploadAdapter';
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs, between} from "vuelidate/lib/validators";
    import VueSlideBar from 'vue-slide-bar'
    import system_app_settings from "./system_app_settings.vue";

    const options = {
        toast: {
            timeout: 2000,
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(CKEditor);
    Vue.use(Snotify, options)
    export default {
        name: 'textEditor',
        mixins: [validationMixin],
        components: {
            VueSlideBar,
            system_app_settings
        },
        data() {
            return {
                form: {
                    latitude: "",
                    longitude: "",
                    default_map_zoom: "",
                    default_map: "mapbox://styles/mapbox/light-v10",
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                sliderCustomzie: {
                    lineHeight: 10,
                    processStyle: {
                        backgroundColor: '#008ffb'
                    },
                    tooltipStyles: {
                        backgroundColor: '#008ffb',
                        borderColor: '#008ffb'
                    }
                },
                selectedFile: null,
                selectedFile1: null,
                url: null,
                url1: null,
                image_error: false,
                client_url: null,
                isDisabled: false,
                isDisabled1: false,
            }
        },
        validations: {
            form: {
                latitude: {
                    required,
                    between(value) {
                        return between(-90, 90)(value)
                    }
                },
                longitude: {
                    required,
                    between(value) {
                        return between(-180, 180)(value)
                    }
                },
            }
        },
        mounted() {
            this.getAppSettings();
        },
        methods: {
            saveMapStylee(value){                
                // this.map.remove();
                this.form.is_heatMap = true;
                document.getElementById('satellite-v9').checked = false;
            

            },
               saveMapStyleee(value){                
                // this.map.remove();
                this.form.is_heatMap = false;
                document.getElementById('heat-map').checked = false;
            

            },
            onSubmit() {

                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                if (!this.image_error) {
                    const fd = new FormData();
                    if (this.selectedFile?.type && this.selectedFile?.type.indexOf('image') !== -1) {
                        fd.append('file', this.selectedFile, this.selectedFile.name)
                    }
                    else {
                        fd.append('file', null)
                    }

                    if (this.selectedFile1?.type && this.selectedFile1?.type.indexOf('image') !== -1) {
                        fd.append('file1', this.selectedFile1, this.selectedFile1.name)
                    }else {
                        fd.append('file1', null)
                    }

                    fd.append('data', JSON.stringify(this.form))
                    API.updateMapSettings(
                        fd,
                        data => {
                            console.log(data);
                            this.isDisabled = false;
                            if(data.status === 200){
                                this.$v.form.$reset();
                                this.$snotify.success(data.message, {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            else{
                                if(data.type === 'logo'){
                                    this.$refs.fileInput.$el.value=null;
                                    this.url = '';
                                    this.selectedFile = ''
                                }else if(data.type === 'icon'){
                                    this.$refs.fileInput1.$el.value=null;
                                    this.url1 = '';
                                     this.selectedFile1 = ''
                                }
                                this.$snotify.error(data.message, {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        },
                        err => {
                            this.isDisabled = false;
                            this.$snotify.error(err.message, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    )
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: 'Image size is not valid.',
                    })
                }
            },
            getAppSettings() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getAppSettings(
                    data,
                    data => {
                        this.form.latitude = data.data.latitude;
                        console.log('this.form.latitude :', this.form.latitude);
                        this.form.longitude = data.data.longitude;
                        this.form.default_map_zoom = data.data.default_map_zoom;
                        this.form.default_map = (data.data.default_map) ? data.data.default_map : 'mapbox://styles/mapbox/light-v10';
                    },
                    err => {
                    }
                )
            },
        }
        ,
        beforeMount() {
            this.getAppSettings();
        }
    }
</script>
<style scoped>
    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
    }
    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc(2 * var(--ck-spacing-large));
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: scroll;
    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 15.8cm;
        min-height: 21cm;

        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl(0, 0%, 82.7%) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

        /* Center the "page". */
        margin: 0 auto;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl(203, 100%, 50%);
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc(2 * var(--ck-spacing-large));
        margin-right: calc(2 * var(--ck-spacing-large));
    }

    .ck.ck-editor__editable_inline {
        border: 1px solid #e3e3e3;
        overflow: auto;
        padding: 0 var(--ck-spacing-standard);
    }

.btn-2 {
  display: flex;
  align-items: center;
  --background: #2b3044;
  --background-hover: #1e2235;
  background: none;
  border: 1px solid lighten(gray, 24%);
  height: 48px;
  padding: 0 24px 0 16px;
  letter-spacing: .25px;
  border-radius: 18px;
  cursor: pointer;
}

.btn-delete {
  font-size: 12px;
  color: red;
}

.button-62 {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.btn-2:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.btn-2:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}
@media (max-width: 1150px) {
    .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
}
</style>
